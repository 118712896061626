import * as React from "react"

import '../../styles/home.css'
import '../../styles/skeleton.css'
import '../../styles/normalize.css'
import Meta from "../../components/Meta"
import Header from "../../components/Header"
import Socials from "../../components/Socials"
import Footer from "../../components/Footer"
import Status from "../../components/Status"

const IndexPage = () => {
    return (
        <div className="container main">
            <title>Presentations</title>
            <Meta></Meta>
            <Header/>
            <div className="header row">
                <div className="title-secondary twelve columns">
                    <div className="intro">
                    <h6>Expectations</h6>
                    </div>
                    <div className="headline">
                    <h1>New Opportunities</h1>
                    </div>
                    <div className="status row">
                        <div className="three columns visa-container">
                            <h6 className="visa">F1 Student Visa / CPT</h6>
                        </div>
                        <div className="three columns visa-container">
                            <h6 className="visa">Sponsorship Required</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="twelve columns">
                    <div className="intro">
                        I am currently set on working as a visual designer, but I am also decently familiar with UXR and Mobile Development.
                        <br/><br/><br/>
                        <h6>What I am looking for</h6>
                        <ul>
                            <li>A fast paced environment, medium intensity work.</li>
                            <li>Creative Freedom.</li>
                            <li>Worked with more than a couple of early stage startups, love working with a blank slate.</li>
                            <li>Flexible schedule, where what's important is work delivered.</li>
                            <li>Working with Mobile and Web Developers is super comfortable with me, cause I have done app development myself.</li>
                            <li>To experiment with interfaces.</li>
                            <li>Hybrid or In-Person, anywhere in the States.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <Socials></Socials>
            <Footer></Footer>
        </div>
    )
}

export default IndexPage